<template>
  <v-app>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254" v-if="role_id == undefined">
        Add Role
      </h1>
      <h1 class="mt-5 mb-5" style="color: #3f4254" v-if="role_id != undefined">
        Edit Role
      </h1>
    </div>

    <div class="pt-5 pl-5 pr-5">
      <v-row>
        <v-col cols="5">
          <b-form-group
            label-cols="4"
            label-cols-lg="2"
            label-size="lg"
            label="Name"
          >
            <b-form-input
              v-model="name_input"
              size="sm"
              placeholder="Enter text"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="2"
            label-size="lg"
            label="Code"
          >
            <b-form-input
              v-model="code_input"
              size="sm"
              placeholder="Enter text"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="2"
            label-size="lg"
            label="Note"
          >
            <v-textarea
              placeholder="Enter text..."
              class=""
              rows="4"
              auto-grow
              v-model.trim="note_input"
              solo
            ></v-textarea>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="2"
            label-size="lg"
            label="User"
          >
            <v-autocomplete
              v-model="user_ids"
              :disabled="isUpdating"
              :items="user_tags"
              filled
              chips
              color="blue-grey lighten-2"
              label=""
              item-text="name"
              item-value="id"
              multiple
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeUserTag(data.item)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </b-form-group>
        </v-col>
        <v-col cols="7">
          <v-container>
            <!-- <v-row>
              <template v-for="(permission, iPer) in permissions">
                <v-col cols="4" :key="iPer">
                  <v-checkbox :label="permission.name" v-model="permission.checked"></v-checkbox>
                </v-col>
              </template>
            </v-row> -->
            <!-- <v-row>
              <v-col cols="5" class="d-flex align-items-center pb-0">
                <label class="text-body-1 font-weight-medium mb-6">Modules:</label>
                <v-select
                  class="ml-2"
                  label="select"
                  v-model="module_search_input"
                  :items="modules"
                  item-text="label"
                  item-value="value"
                  solo
                ></v-select>
              </v-col>
              <v-col cols="3" class="p-0 m-0"></v-col>
              <v-col cols="4" class="p-0">
                <div class="input-icon mt-5 float-left">
                  <input type="text" class="form-control" placeholder="Search..." v-model="search_input"/>
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </v-col>
            </v-row> -->
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-vertical-center table-head-bg"
              >
                <thead class="thead-ipp">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Permission</th>
                    <th scope="col">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(permission, iPer) in permissions">
                    <tr :key="iPer">
                      <td class="p-0 pl-2">
                        <v-checkbox v-model="permission.checked"></v-checkbox>
                      </td>
                      <td class="p-0">
                        <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          >{{ permission.name }}</span
                        >
                      </td>
                      <td class="p-0">
                        <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          >{{ displayTextModule(permission.module) }}</span
                        >
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-container>
        </v-col>
        <v-col cols="12">
          <center>
            <v-btn
              class="btn font-weight-bold text-uppercase"
              x-large
              color="primary"
              @click="submit"
              >Submit</v-btn
            >
          </center>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>

<script>
import ApiService from "@/service/api.service";
export default {
  name: "Form",
  props: ["role_id"],
  data() {
    return {
      name_input: "",
      code_input: "",
      note_input: "",
      permissions: [],
      filteredPermissions: [],
      user_tags: [],
      user_ids: [],
      isUpdating: false,
      modules: [
        { label: "", value: 0 },
        { label: "Student Management", value: 1 },
        { label: "Marking", value: 2 },
        { label: "Learning Contents", value: 3 },
        { label: "Business Management", value: 4 },
        { label: "Global", value: 5 },
      ],
      module_search_input: 0,
      search_input: "",
    };
  },
  computed: {},
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
  },
  async created() {
    await this.getAllPermission();
    await this.getAllUser();
    await this.setInputData();
  },
  methods: {
    async getAllUser() {
      let vm = this;
      await ApiService.get("prep-app/user?limit=-1").then(function (response) {
        if (response.status == 200) {
          vm.user_tags = response.data;
        }
      });
    },
    async setInputData() {
      if (this.role_id != undefined) {
        let vm = this;
        await ApiService.get("prep-app/role/" + this.role_id).then(function (
          response
        ) {
          if (response.status == 200) {
            let data = response.data;
            vm.name_input = data.name;
            vm.code_input = data.code;
            vm.note_input = data.note;
            vm.permissions.forEach((item_per) => {
              data.permission.forEach((item_per_res) => {
                if (item_per.id == item_per_res.id) {
                  item_per.checked = true;
                }
              });
            });

            vm.user_ids = data.users.map((user) => {
              return user.id;
            });
          }
        });
      }
    },
    async getAllPermission() {
      let vm = this;
      await ApiService.get("prep-app/permission?limit=-1").then(function (
        response
      ) {
        if (response.status == 200) {
          vm.permissions = vm.convertArrayPer(response.data);
        }
      });
    },
    convertArrayPer(array) {
      for (let i = 0; i < array.length; i++) {
        array[i].checked = false;
      }
      return array;
    },
    removeUserTag(item) {
      let index = this.user_ids.indexOf(item.id);
      if (index >= 0) this.user_ids.splice(index, 1);
    },
    submit() {
      let permission_ids = [];
      this.permissions.forEach((element) => {
        if (element.checked == true) {
          permission_ids.push(element.id);
        }
      });
      let data = {
        name: this.name_input,
        code: this.code_input,
        note: this.note_input,
        permission_ids: permission_ids,
        user_ids: this.user_ids,
      };
      // console.log(data);
      this.$emit("submitForm", data);
    },
    displayTextModule(value) {
      if (value == null || value == undefined || value == "") {
        return null;
      }
      let result = this.modules.filter(
        (moduleItem) => moduleItem.value == value
      );
      return result[0].label;
    },
  },
};
</script>

<style></style>
